@import "../../assets/scss/variables.scss";

.world {
  &-name {
    font-size: 26px;
    color: #000000;
    letter-spacing: 1.62px;
    line-height: 26px;
    font-weight: 600;
    font-family: Hiragino Kaku Gothic;
    margin: 0 0 10px;
    &-title {
      font-size: 20px;
      color: #000000;
      line-height: 60px;
      font-weight: 600;
      font-family: Hiragino Kaku Gothic;
      margin: 0;
    }
  }
  &-list {
    display: flex;
    padding: 40px 0 0 0;
    &-img {
      margin: 0 72px 0 0;
      padding: 0;
    }
    &-text {
      font-size: 16px;
      color: #000000;
      line-height: 36px;
      font-family: Hiragino Kaku Gothic;
      margin: 0;
      &-title {
        font-size: 20px;
        color: #000000;
        line-height: 60px;
        font-weight: 600;
        font-family: Hiragino Kaku Gothic;
        margin: 0 0 20px;
      }
    }
  }
  &-video {
    &-container {
      display: flex;
      padding: 40px 0 0;
    }
    &-pdf {
      width: 37%;
      margin: 0;
      &-list {
        margin: 0 0 40px;
        &-container {
          margin: 0;
          padding: 0;
        }
        &-title ,
        &-text {
          margin: 0;
          padding: 0;
        }
        &-title {
          font-size: 18px;
          color: #000000;
          line-height: 26px;
          margin: 0 0 10px;
        }
      }
    }
    &-list {
      width: 63%;
      &-item {
        position: relative;
        padding-top: 56.25%;
        margin: 0 0 80px;
        &:last-child {
          margin: 0;
        }
        &-video {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}


@include mq(md) {
  .world {
    &-name {
      font-size: 20px;
      letter-spacing: 1.25px;
      margin: 0 0 0;
      &-title {
        font-size: 16px;
        line-height: 30px;
      }
    }
    &-list {
      display: block;
      padding: 26px 0 0 0;
      &-img {
        margin: 0 0 20px;
      }
      &-text {
        &-title {
          line-height: 30px;
          margin: 0 0 20px;
        }
      }
    }
    &-video {
      &-container {
        display: block;
        padding: 26px 0 0;
      }
      &-pdf {
        width: 100%;
      }
      &-list {
        width: 100%;
        &-item {
          margin: 0 0 40px;
        }
      }
    }
  }
}
.loading-overlay {
  color: #fff;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    z-index: 999999;
  }
}

.loading {
  color: #DE0000;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    margin: 0 0 60px;
  }
}
@import "../../../../assets/scss/variables.scss";

.information {
  &-container {
    margin: 0 0 150px;
  }
  &-cards {
    margin: 0 0 30px;
  }
  &-tags {
    margin: 0 0 66px;
  }
  &-btn {
    margin: 0 0 60px;
    text-align: center;
  }
}


@include mq(md) {
  .information {
    &-container {
      margin: 0 0 60px;
    }
  }
}
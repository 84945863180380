@import "../../assets/scss/variables.scss";

.information {
  &-list {
    width: 100%;
    max-width: 1258px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    &__notfound {
      text-align: center;
      padding: 37px 0;
      font-size: 14px;
      margin: 32px 20px 100px;
      font-weight: 300;
      border-top: 1px solid #E6E6E6;
      border-bottom: 1px solid #E6E6E6;
    }
  }
}

.information-contents {
  width: 100%;
  max-width: 840px;
  padding: 0 20px 0;
  margin: 0 auto 172px;
  box-sizing: border-box;
	:global(h1) ,
	:global(h2) ,
	:global(h3) ,
	:global(h4) ,
	:global(h5) ,
	:global(h6) {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    letter-spacing: 1.25px;
    line-height: 26px;
    margin: 0 0 23px;
	}
	:global(p) {
    font-size: 16px;
    color: #000000;
    line-height: 36px;
    margin: 0 0 62px;
    &:last-child {
      margin: 0;
    }
	}
}

@include mq(md) {
  .information {
    &-list {
      max-width: initial;
      max-width: auto;
      margin: 0 auto;
      padding: 0 10px;
      display: block;
    }
  }  
}
@import "../../assets/scss/variables.scss";

.news {
  &-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 0 15px;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px;
    &-title {
      font-size: 26px;
      color: #000000;
      letter-spacing: 1.62px;
      line-height: 26px;
      font-weight: 600;
      margin: 0;
    }
  }
}

@include mq(md) {
  .news {
    &-container {
      width: 100%;
      max-width: initial;
      max-width: auto;
      margin: 0 auto;
      padding: 0 20px;
    }
    &-header {
      flex-direction: column;
      justify-content: left;
      margin: 0 0 40px;
      &-title {
        order: 2;
        width: 100%;
        text-align: left;
      }
      &-select {
        order: 1;
        margin: 0 0 60px;
      }
    }
  }
  
}
@import "../../assets/scss/variables.scss";

.login {
  &-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto 120px;
  }
  &-form {
    &-label {
      display: block;
      margin: 0 0 20px;
      &-error {
        color: #DE0000;
      }
    }
  }
  &-btn {
    &-group {
      text-align: center;
      margin-top: 20px;
      & > *  {
        margin: 0 10px;
      }
    }
  }
}


@include mq(md) {
  .login {
    &-container {
      padding: 0 20px;
    }
  }
}
@import "../../../../assets/scss/variables.scss";

.archives {
  &-container {
    position: relative;
    margin: 0 0 158px;
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 704px;
    background-size: cover;
    background-position: center top;
    &::before {
      content: "";
      height: 420px;
      width: 420px;
      display: block;
      background: rgba(153, 0, 0, .6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
    }
  }
  &-inner {
    padding: 168px 0 0;
    margin: 0 100px;
    display: flex;
  }
  &-img {
    width: 575px;
    img {
      width: 100%;
    }
  }
  &-info {
    width: calc(100% - 575px);
    margin: 67px 0 0 0;
    &-title {
      font-size: 26px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 1.62px;
      line-height: 26px;
      margin:  0 0 23px;
    }
   &-text {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 42px;
    margin: 0 0 38px;
   }
  }
}

@include mq(lg) {
  .archives {
    &-container {
      position: relative;
      margin: 0 0 80px;
      background: #D1D1D1;
    }
    &-bg {
      position: static;
      height: 206px;
      &::before {
        content: none;
      }
      img {
        width: 100%;
      }
    }
    &-inner {
      padding: 0 30px 80px;
      margin: -146px 0 0;
      display: block;
    }
    &-img {
      width: 100%;
    }
    &-info {
      width: 100%;
      margin: 50px 0 0 0;
      &-title {
        font-size: 20px;
      }
     &-btn {
       text-align: center;
     }
    }
  }
}
@import "../../../../assets/scss/variables.scss";


.news {
  &-container {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto 190px;
    padding: 0 20px;
  }
  &-list {
    margin: 0 0 40px;
  }
  &-btn {
    text-align: left;
  }
}


@include mq(md) {
  .news {
    &-container {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto 100px;
      padding: 0 20px;
    }
    &-list {
      margin: 0 0 40px;
    }
    &-btn {
      text-align: center;
    }
  }
}
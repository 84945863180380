@import "../../assets/scss/variables.scss";

.mypage {
  &-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  &-text {
    font-size: 18px;
    margin: 0 0 30px;
  }
  &-head {
    flex-grow: 1;
    margin: 0 0 50px;
    text-align: center;
    &-item {
      font-size: 18px;
    }
  }
  &-btns {
    text-align: right;
    &-group {
      text-align: right;
      &__center {
        text-align: center;
      }
      > * {
        margin: 0 5px;
      }
    }
  }
  &__notfound {
    text-align: center;
    padding: 37px 0;
    font-size: 14px;
    margin: 0;
    font-weight: 300;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
  }
  &-billing {
    &-display {
      text-align: center;
      margin: 0 0 30px;
      p {
        font-size: 16px;
        margin: 0 0 20px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.history {
  &-table {
    min-width: 650px;
  }
}

.form {
  &-stripe {
    border: 1px solid #CCCCCC;
    padding: 17px 20px 18px;
    &-title {
      font-size: 14px;
      color: #333333 !important;
      font-weight: bold;
      margin: 0 0 12px;
    }
    &-wrapper {
      margin-bottom: 30px;
    }
  }
  &-load {
    &-elements {
      margin-top: 28px !important;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CCCCCC;
    }
  }
}
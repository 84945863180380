@import "../../assets/scss/variables.scss";

.list-news {
  border-top: 1px solid #E6E6E6;
  &-item {
    &-link {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #222;
      padding: 20px 0 10px;
      border-bottom: 1px solid #E6E6E6;
      &-active {
        .list-news-item-text {
          text-decoration: underline;
        }
        &:hover {
          opacity: .7;
          p {
            text-decoration: none;
          }
        }
      }
    }
    &-date {
      font-family: "Heebo-Bold";
      font-size: 46px;
      text-align: center;
      line-height: 1;
      z-index: 2;
      &-top {
        font-size: 14px;
        margin: 0 0 5px;
      }
    }
    &-text {
      font-size: 14px;
      font-weight: 300;
      margin: 0 20px;
    }
    &__notfound {
      text-align: center;
      padding: 37px 0;
      font-size: 14px;
      margin: 0;
      font-weight: 300;
      border-bottom: 1px solid #E6E6E6;
    }
  }
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  &-item {
    position: relative;
    font-size: 16px;
    color: #000;
    letter-spacing: 1px;
    line-height: 27px;
    padding-left: 30px;
    margin-bottom: 26px;
    &::before {
      position: absolute;
      top: 9px;
      left: 0;
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      background: #DE0000;
      border-radius: 50%;
    }
  }
  &-table {
    min-width: 650px;
  }
}

.archives {
  &-list {
    display: flex;
    width: 100%;
  }
  &-item {
    margin: 0 5% 0 0;
    padding: 0;
    width: 30%;
    text-align: center;
    &:last-child {
      margin: 0;
    }
    &-img {
      margin: 0;
      padding: 0;
      img {
        width: 100%;
        vertical-align: bottom;
        margin: 0 0 20px;
        box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.20);
      }
    }
    &-caption {
      padding: 3px 27px;
      display: inline-block;
      border: 1px solid #D6D6D6;
      border-radius: 20px;
      font-size: 14px;
      color: #222222;
      font-weight: 600;
    }
  }
}

@include mq(md) {
  .archives {
    &-list {
      flex-wrap: wrap;
    }
    &-item {
      margin: 0 10% 40px 0;
      width: 45%;
      &:nth-child(even) {
        margin: 0;
      }
      &-caption {
        padding: 8px 14px;
        font-size: 12px;
      }
    }
  }
  .checkout {
    &-table {
      width: 480px;
    }
  }
}
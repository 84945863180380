@import "../../assets/scss/variables.scss";

.cms-contents {
  width: 100%;
  max-width: 840px;
  padding: 0 20px 0;
  margin: 0 auto 172px;
  box-sizing: border-box;
	:global(h1) ,
	:global(h2) ,
	:global(h3) ,
	:global(h4) ,
	:global(h5) ,
	:global(h6) {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    letter-spacing: 1.25px;
    line-height: 26px;
    margin: 0 0 23px;
	}
	:global(p) {
    font-size: 16px;
    color: #000000;
    line-height: 36px;
    margin: 0 0 62px;
    &:last-child {
      margin: 0;
    }
	}
  :global(table) {
    border-top: 1px solid #ccc;
    border-collapse: collapse;
    width: 100% !important;
    tr {
      td:first-child {
        width: 20%;
        font-weight: bold;
      }
    }
    th, td {
      border-bottom: 1px solid #ccc;
      padding: 10px;
      margin: 0;
      line-height: 1.9;
      box-sizing: border-box;
      p {
        margin: 0;
        padding: 0;
        line-height: 1;
      }
    }
  }
  :global(.layout-list) {
    display: flex;
    align-items: flex-start;
    margin: 0 0 36px;
    :global(.layout-list-group) {
      display: flex;
      > *:first-child {
        margin-right: 5px;
      }
    }
    &:last-child {
      margin: 0;
    }
    > *:first-child {
      margin-right: 10px;
    }
  }
}


@include mq(sm) {
  .cms-contents {
    :global(.layout-list) {
      display: block;
      text-align: center;
      :global(.layout-list-group) {
        display: block;
        text-align: center;
      }
      p {
        text-align: left;
      }
    }
  }
}


.cms-load {
  position: fixed;
  height: calc(100% - 70px);
  width: 100%;
  top: 70px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cms {
  &-header {
    width: 100%;
    max-width: 1120px;
    padding: 40px 20px 30px;
    margin: 0 auto;
    box-sizing: border-box;
    &-title {
      text-transform: uppercase;
      font-family: "Heebo-Light";
      font-size: 32px;
      color: #000000;
      text-align: center;
      line-height: 32px;
    }
    &-back {
      display: flex;
      align-items: center;
      display: inline-block;
      margin: 0;
      font-size: 14px;
      color: #000000;
      line-height: 26px;
      img {
        margin-right: 9px;
      }
      span {
        text-decoration: underline;
      }
      &:hover {
        cursor: pointer;
        opacity: .7;
        span {
          text-decoration: none;
        }
      }
    }
  }
  &-info {
    width: 460px;
    padding: 70px 30px 60px;
    position: relative;
    background: rgba(255,255,255,0.90);
    box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.15);
    &-container {
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      min-height: 510px;
      margin-bottom: 70px;
    }
    &-date {
      font-family: "Heebo-Bold";
      font-size: 46px;
      text-align: center;
      position: absolute;
      top: -39px;
      left: -16px;
      line-height: 1;
      z-index: 2;
      &-top {
        font-size: 14px;
        margin: 0 0 5px;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 28px;
      color: #333333;
      line-height: 40px;
      margin: 0 0 20px;
    }
    &-tags {
      justify-content: flex-start;
      &-item {
        margin: 0 10px 10px 0;
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.contact {
  &-info {
    &-container {
      padding: 50px 200px 30px;
      border: 1px solid #c5c5c5;
      &-ihic {
        padding: 60px;
        text-align: center;
      }
    }
    &-title {
      margin: 0 0 30px;
      font-size: 24px;
      color: #000000;
      line-height: 26px;
      font-weight: 600;
      &-sub {
        font-size: 18px;
        color: #222222;
        line-height: 18px;
        font-weight: 600;
        margin: 0 0 16px;
      }
    }
    &-address {
      font-size: 16px;
      color: #222222;
      line-height: 18px;
      margin: 0 0 34px;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      &-ihic {
        justify-content: center;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      width: 50%;
      margin: 0 0 30px;
      &-ihic {
        width: auto;
      }
      &-label ,
      &-text {
        margin: 0;
        padding: 0;
      }
      &-label {
        width: 84px;
        background: #000000;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        line-height: 18px;
        font-weight: 600;
        padding: 3px 10px;
        margin: 0 16px 0 0;
        text-align: center;
        font-family: 'Heebo-SemiBold';
      }
      &-text {
        font-size: 36px;
        color: #222222;
        letter-spacing: -1px;
        line-height: 18px;
        font-family: 'Heebo-SemiBold';
        &-email {
          font-size: 20px;
          color: #222222;
          line-height: 18px;
          letter-spacing: 0;
        }
      }
      &-email {
        width: 100%;
      }
    }
  }
}


@include mq(md) {
  .contact {
    &-info {
      &-container {
        padding: 35px 20px 10px;
        &-ihic {
          padding: 40px 20px 40px;
          text-align: left;
        }
      }
      &-address {
        font-size: 14px;
      }
      &-list {
        display: block;
      }
      &-item {
        width: 100%;
        &-text {
          font-size: 30px;
          &-email {
            font-size: 12px;
          }
        }
      }
    }
  }
}
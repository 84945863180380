@import "../../../../assets/scss/variables.scss";

.world {
  &-container {
    width: 100%;
    max-width: 787px;
    margin: 0 auto 180px;
    display: flex;
  }
  &-img {
    padding-bottom: 14px;
  }
  &-info {
    &-img {
      display: none;
    }
  }
}


@include mq(md) {
  .world {
    &-container {
      width: 100%;
      max-width: initial;
      max-width: auto;
      margin: 0 auto 70px;
    }
    &-img {
      display: none;
    }
    &-info {
      text-align: center;
      &-img {
        display: block;
        margin: 0 0 60px;
      }
    }
  } 
}
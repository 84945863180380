@import "../../assets/scss/variables.scss";


.about {
  &-text {
    margin: 0 0 53px;
    font-size: 16px;
    color: #222;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
  }
  &-btn {
    margin:  0 0 120px;
  }
}

.purpose {
  margin: 0 0 92px;
  &-img {
    img {
      width: 100%;
    }
  }
  &-text {
    padding: 0 0 0 10px;
    p {
      font-size: 16px;
      color: #000000;
      line-height: 36px;
      margin: 0 0 24px;
    }
    &-right {
      text-align: right;
    }
  }
}

.activies {
  &-text {
    padding: 0 0 0 30px;
  }
  &-list {
    margin-bottom: 60px;
  }
  &-btn {
    margin: 0 0 28px;
  }
  &-img {
    img {
      width: 100%;
    }
  }
}

.greeting {
  &-title {
    margin-bottom: 66px;
  }
  &-text {
    background: #F5F5F5;
    padding: 84px 0 84px;
    p {
      font-size: 16px;
      color: #000;
      line-height: 36px;
      margin: 0 0 60px 0;
    }
    &-inner {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1120px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-name {
    letter-spacing: 1px;
    text-align: right;
    &-container {
      display: flex;
      justify-content: space-between;
      line-height: 26px;
      p {
        margin: 0;
      }
    }
  }
}

.founder {
  margin: 0 0 50px;
  &:last-child {
    margin: 0;
  }
  &-container {
    max-width: 840px;
  }
  &-title {
  font-size: 26px;
  color: #000;
  letter-spacing: 1.62px;
  line-height: 26px;
  margin: 0 0 44px;
  }
  &-date {
    text-align: right;
  }
  &-table {
    width: 100%;
    border-top: 1px solid #E6E6E6;
    &-name {
      width: 20%;
      padding: 0 25px;
    }
    &-title {
      width: 80%;
      padding: 0 0 0 30px;
    }
    td {
      border-bottom: 1px solid #E6E6E6;
      font-size: 18px;
      color: #000;
      line-height: 60px;
    }
  }
}

.organizational {
  &-title {
    font-size: 26px;
    color: #000;
    letter-spacing: 1.62px;
    line-height: 26px;
    font-weight: 600;
  }
  &-sub-title {
    font-size: 20px;
    color: #000;
    line-height: 60px;
    font-weight: 600;
  }
  &-table {
    width: 100%;
    border-top: 1px solid #E6E6E6;
    td {
      border-bottom: 1px solid #E6E6E6;
      color: #000;
      line-height: 60px;
    }
    &-date {
      font-size: 13px;
      color: #000;
      text-align: right;
      line-height: 26px;
    }
    &-name {
      width: 20%;
      padding: 0 25px;
      font-size: 16px;
    }
    &-title {
      width: 20%;
      padding: 0 25px;
      font-size: 18px;
    }
    &-company {
      width: 60%;
      padding: 0 0 0 30px;
      font-size: 16px;
    }
  }
  &-container {
    max-width: 840px;
  }
}

@include mq(md) {
  .about {
    &-text {
      text-align: left;
    }
  }
  .activies {
    &-text {
      padding: 0 0 0 9px;
    }
    &-img {
      margin: 0 0 40px;
    }
  }
  .greeting {
    &-name {
      font-weight: bold;
      &-container {
        display: block;
        text-align: right;
        p {
          margin: 0;
        }
      }
    }
  }
  .founder {
    &-table {
      &-name ,
      &-title {
        width: 100%;
        padding: 0 0;
        display: block;
      }
      tr {
        display: block;
        border-bottom: 1px solid #E6E6E6;
        padding: 16px 0;
      }
      td {
        border-bottom: none;
        line-height: 1.65;
      }
    }
  }
  .organizational {
    &-table {
      tr {
        display: block;
        border-bottom: 1px solid #E6E6E6;
        padding: 16px 0;
      }
      td {
        border-bottom: none;
        line-height: 1.65;
      }
      &-name ,
      &-title ,
      &-company {
        width: 100%;
        padding: 0 0;
        display: block;
      }
    }
  }
}
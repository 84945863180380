@import "../../assets/scss/variables.scss";

.title {
	&-hand {
		line-height: 1.35;
		transform-origin: 50% 50%;
		transform: rotate(-3deg);
		& img {
			vertical-align: bottom;
		}
		&-left {
			display: inline-block;
		}
	}
	&-sub {
		font-size: 26px;
		line-height: 1;
		margin: 36px 0 0;
		font-weight: bold;
	}
	&-group {
		margin: 0 0 0;
		padding: 10px 0 0;
		overflow: hidden;
		text-align: left;
		&-text {
			font-size: 16px;
			font-weight: 600;
			padding: 29px 0;
			margin: 0;
			line-height: 42px;
		}
	}
}

@include mq(md) {
	.title {
		&-hand {
			line-height: 1.35;
			transform-origin: 50% 50%;
			transform: rotate(-3deg);
			padding: 0 50px;
			& img {
				vertical-align: bottom;
				width: 100%;
			}
		}
		&-sub {
			font-size: 26px;
			line-height: 1;
			margin: 36px 0 0;
			font-weight: bold;
		}
		&-group {
			margin: 0 0 30px;
			padding: 10px 0 0;
			overflow: hidden;
		}
	}
}
@import "../../assets/scss/variables.scss";

.title {
	font-family: "Heebo-Light";
	font-size: 68px;
	line-height: 1;
	margin: 0 0 20px;
	text-transform: uppercase;
	&-wrapper {
		padding: 45px 0 0;
		margin: 68px 100px 62px;
		position: relative;
		&::before {
			content: "";
			display: block;
			height: 4px;
			width: 50px;
			background: #DE0000;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.sub-title {
	font-size: 24px;
	line-height: 1;
	font-weight: 600;
}

.container {
	padding: 0;
	margin: 0 0 58px;
	&-noimg {
		border-bottom: 1px solid #ddd;
	}
}

.main-visual {
	position: relative;
	height: 400px;
	background-size: cover;
	background-position: center top;
	&-img {
		width: 100%;
		vertical-align: bottom;
	}
	&-catch {
		position: absolute;
		top: -100px;
		right: 40px;
		width: 320px;
		height: 320px;
		background: rgba(34, 34, 34, .6);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


@include mq(md) {
  .title {
		font-size: 20px;
		&-wrapper {
			padding: 25px 0 0;
			margin: 81px 20px 52px;
			&::before {
				height: 2px;
				width: 25px;
			}
		}
	}

	.sub-title {
		font-size: 32px;
	}

	.container {
		padding: 0;
		margin: 0 0 30px;
	}
	
	.main-visual {
		&-catch {
			top: 20px;
			right: 20px;
			width: 160px;
			height: 160px;
			img {
				width: 75%;
			}
		}
	}
}
@import "../../assets/scss/variables.scss";

.notFound {
  &-container {
    margin-bottom: 80px;
  }
  &-text {
    &-container {
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 1.7;
      margin-bottom: 50px;
    }
  }
  &-btn {
    &-container {
      text-align: center;
      background: #DE0000;
      padding: 80px 20px 70px;
      &-inner {
        margin-bottom: 0;
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.header {
  background: #DE0000;
  padding: 23px 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9998;
  top: 0;
  left: 0;
  &-title {
    width: 180px;
    margin: 0;
    padding: 0;
    line-height: 1;
    a {
      display: block;
      line-height: 1;
      font-size: 16px;
    }
    img {
      margin: 0;
      width: 100%;
    }
  }
  &-list {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    &-item {
      margin: 0 25px;
      a {
        display: block;
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
        transition: $short-time;
        &:hover {
          opacity: .6;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &-btn {
        padding: 0 17px;
        cursor: pointer;
        transition: $short-time;
        line-height: 1;
        img {
          width: 16px;
          height: 16px;
        }
        &:hover {
          opacity: .6;
        }
      }
    }
  }
}

.header-overlay {
  position: fixed;
  top: -150%;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(222,0,0,0.90);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top $short-time;
  &-active {
    top: 0;
  }
  &-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    outline: none;
    background: none;
    height: 16px;
    width: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    img {
      width: 100%;
      height: 16px;
      width: 16px;
    }
  }
  &-inner {
    display: flex;
    align-items: flex-start;
  }
  &-list {
    margin: 0 80px 0 0;
    padding: 0;
    list-style-type: none;
    &:last-child {
      margin: 0;
    }
    a {
      color: #fff;
      display: block;
      text-decoration: none;
      transition: $short-time;
      &:hover {
        opacity: .6;
      }
    }
    &-item {
      margin: 0 0 39px;
      padding: 0;
      a {
        font-size: 16px;
        font-weight: 600;
      }
      &-list {
        margin: 28px 0 0 20px;
        padding: 0 0 11px;
        list-style-type: none;
        a {
          font-weight: 300;
        }
        &-item {
          margin: 0 0 10px;
          a {
            &::before {
              content: "-";
              margin-right: 5px;
              display: inline-block;
            }
          }
        }
      }
      &-cart {
        a {
          display: flex;
          align-items: center;
        }
      }
      &-count {
        display: inline-block;
        background: #fff;
        font-size: 12px;
        color: #DE0000;
        width: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 1;
        padding: 3px 0;
        margin: 0 0 0 10px;
      }
    }
  }
}

@include mq(lg) {
  .header {
    &-list {
      &-item {
        display: none;
        &-btn {
          display: block;
        }
      }
    }
  }
}

@include mq(md) {
  .header {
    padding: 18px 10px 18px 20px;
  }

  .header-overlay {
    padding: 76px 90px;
    overflow-y: scroll;
    align-items: flex-start;
    &-btn {
      top: 20px;
      right: 20px;
    }
    &-inner {
      display: block;
    }
    &-list {
      margin: 0;
      &-item {
        margin: 0 0 28px;
        &-list {
          margin: 18px 0 0 20px;
          padding: 0 0;
          &-item {
            margin: 0 0 10px;
            &:last-child {
              margin: 0;
            }
            a {
              &::before {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.form {
  &-load {
    &-elements {
      margin-top: 28px !important;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CCCCCC;
    }
  }
  &-control {
    margin-bottom: 30px;
  }
  &-label {
    font-size: 14px;
    color: #333333 !important;
    font-weight: bold;
    transform: translate(0, 0) scale(1) !important;
    margin: 0 0 12px;
    &:global(.Mui-error) {
      color: #f44336 !important;
    }
  }
  &-checkbox {
    :global(.MuiFormControlLabel-label) {
      font-size: 14px;
    }
  }
  &-input {
    margin-top: 28px !important;
    border-radius: 0;
    &::before, &::after {
      content: none;
    }
    input ,
    textarea {
      padding: 17.5px 14px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 2px;
      font-size: 16px;
      color: #333333 !important;
      &:disabled {
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }
    &:global(.Mui-error) {
      input {
        color: #f44336;
        border: 1px solid #f44336;
      }
    }
  }
  &-select {
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 27px;
    margin: 0;
    padding: 18px 60px 18px 30px;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    box-sizing: content-box;
    font-size: 18px;
    font-weight: 600;
    font-family: "Hiragino Kaku Gothic ProN";
    color: #222222;
    outline: none;
    &-full {
      width: calc(100% - 90px);
      border-radius: 0;
      font-size: 16px;
    }
    &-container {
      position: relative;
      display: inline-block;
      &-full {
        width: 100%;
      }
      &::before {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%) rotate(90deg);
        font-family: "Hiragino Kaku Gothic ProN";
        content: ">";
        font-size: 20px;
        color: #222222;
        line-height: 20px;
        font-weight: 600;
        z-index: -1;
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.btn {
	border-radius: 0;
	border: 0;
	font-size: 18px;
	font-weight: 600;
	text-transform: none;
	padding: 17px 30px 19px;
	transition: $short-time;
	&:hover {
		opacity: .6;
		background: inherit;
	}
	&-primary {
		background: #000;
		color: #fff;
		&:hover {
			background: #000;
			color: #fff;
		}
	}
	&-secondary {
		background: #fff;
		color: #000;
		&:hover {
			background: #fff;
			color: #000;
		}
	}
	&-danger {
		background: #DE0000;
		color: #fff;
		&:hover {
			background: #DE0000;
			color: #fff;
		}
	}
	:global(.MuiButton-label) {
		line-height: 1;
	}
	:global(.MuiButton-endIcon) {
		margin: 0 0 0 20px;
	}
	&-group {
		display: flex;
		justify-content: center;
		&-item {
			+ .btn-group-item {
				margin-left: 20px;
			}
		}
	}
	&:global(.MuiButton-root.Mui-disabled),
	&:disabled {
		color: #ccc !important;
		opacity: .4;
	}
	&__revers {
		:global(.MuiButton-startIcon) {
			transform: rotate(180deg);
		}
	}
}

.btn-circle {
	border: 1px solid #979797;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	box-sizing: border-box;
	min-width: initial;
	min-width: auto;
	margin: 0 10px;
	span {
		width: auto;
	}
}

@include mq(md) {
	.btn {
		font-size: 14px;
		padding: 17px 20px 19px;
		&-group {
			display: block;
			justify-content: center;
			&-item {
				+ .btn-group-item {
					margin-top: 20px;
					margin-left: 0;
				}
			}
		}
	}
}

.btn-list {
	border-top: 1px solid #C5C5C5;
	border-bottom: 1px solid #C5C5C5;
	a {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 32px 0 32px 30px;
		color: #000000;
		letter-spacing: 1.5px;
		line-height: 26px;
		font-weight: 600;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&-group {
		width: 100%;
		display: flex;
		justify-content: space-between;
		&-2 {
			.btn-list {
				width: 48%;
			} 
		}
		&-3 {
			.btn-list {
				width: 30%;
			} 
		}
		&-4 {
			.btn-list {
				width: 22%;
			} 
		}
	}
}

@include mq(md) {
	.btn-list {
		border-top: 1px solid #C5C5C5;
		border-bottom: 0px;
		a {
			padding: 14px 0 14px 20px;
			img {
				width: 20px;
			}
		}
		&-group {
			display: block;
			border-bottom: 1px solid #C5C5C5;
			&-2 ,
			&-3 ,
			&-4 {
				.btn-list {
					width: 100%;
				} 
			}
		}
	}
	
}
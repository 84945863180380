@import "../../../../assets/scss/variables.scss";

.member {
  &-container {
    margin: 0 0 160px;
  }
  &-title {
    margin: 0 0 70px;
  }
  &-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &-container {
      width: 518px;
      height: 518px;
      margin: 0 auto;
      border: 1px solid #D7D7D7;
      border-radius: 50%;
      position: relative;
    }
    &-img1 ,
    &-img2 ,
    &-img3 ,
    &-img4 ,
    &-img5 ,
    &-img6 ,
    &-img7 ,
    &-img8 ,
    &-img9 {
      position: absolute;
    }
    &-img1 {
      top: -25px;
      left: 132px;
    }
    &-img2 {
      top: -25px;
      left: 308px;
    }
    &-img3 {
      top: 90px;
      right: -6px;
    }
    &-img4 {
      top: 264px;
      right: -40px;
    }
    &-img5 {
      top: 419px;
      right: 62px;
    }
    &-img6 {
      bottom: -30px;
      right: 219px;
    }
    &-img7 {
      top: 419px;
      left: 62px;
    }
    &-img8 {
      top: 264px;
      left: -40px;
    }
    &-img9 {
      top: 90px;
      left: -6px;
    }
  }
}

@include mq(md) {
	.member {
    &-container {
      margin: 0 0 70px;
    }
    &-title {
      margin: 0 0 50px;
    }
    &-btn {
      position: static;
      &-container {
        width: 100%;
        height: auto;
        border: none;
        padding: 240px 20px 200px;
      }
      &-img1 ,
      &-img2 ,
      &-img3 ,
      &-img4 ,
      &-img5 ,
      &-img6 ,
      &-img7 ,
      &-img8 ,
      &-img9 {
        width: 64px;
      }
      &-img1 {
        top: 10px;
        left: 20px;
      }
      &-img2 {
        top: 74px;
        left: auto;
        right: 20px;
      }
      &-img3 {
        top: 0px;
        right: 108px;
      }
      &-img4 {
        top: auto;
        bottom: 95px;
        left: auto;
        right: 20px;
      }
      &-img5 {
        top: auto;
        bottom: 31px;
        right: 91px;
      }
      &-img6 {
        bottom: 105px;
        left: 126px;
        right: auto;
      }
      &-img7 {
        top: auto;
        bottom: 0;
        left: 100px;
      }
      &-img8 {
        top: auto;
        bottom: 71px;
        left: 20px;
      }
      &-img9 {
        top: 74px;
        left: 108px;
      }
    }
  }
}
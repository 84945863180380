@import "../../assets/scss/variables.scss";

.archives {
  &-text {
    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    line-height: 42px;
    margin: 0 0 60px;
  }
  &-title {
    font-size: 26px;
    color: #000000;
    letter-spacing: 1.62px;
    line-height: 26px;
    font-weight: 600;
    margin: 0 0 42px;
  }
}

@include mq(md) {
  .archives {
    &-container {
      margin: 0 0 40px;
    }
  }
}
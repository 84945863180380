@import "../../assets/scss/variables.scss";

.card {
	width: 100%;
	max-width: 418px;
	position: relative;
	margin: 0 20px 0 20px;
	transition: $short-time;
	&-list {
		max-width: 379px;
		margin: 32px 20px 32px;
	}
	&:hover {
		opacity: .7;
	}
	> a {
		text-decoration: none;
		color: #222;
		display: block;
		padding: 36px 0 20px 18px;
	}
	&-date {
		font-family: "Heebo-Bold";
		font-size: 46px;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		line-height: 1;
		z-index: 2;
		&-top {
			font-size: 14px;
			margin: 0 0 5px;
		}
	}
	&-figure {
		position: relative;
		margin: 0;
		padding: 0;
		z-index: 1;
		img {
			width: 100%;
		}
	}
	&-text {
		position: relative;
		margin: -50px 0 0;
		background: rgba(255,255,255,0.90);
		box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.15);
		z-index: 2;
		margin: -60px 31px 0;
		padding: 27px 30px 35px;
	}
	&-title {
		font-size: 14px;
		line-height: 1.7;
	}
}

.cards {
	&-btns {
		text-align: right;
		margin: 0 auto 20px;
		padding: 0 100px;
	}
	&-list {
		overflow: hidden;
	}
}


@include mq(md) {
	.card {
		width: 100%;
		max-width: initial;
		max-width: auto;
		margin: 0 0 20px;
		&-list {
			max-width: initial;
			max-width: auto;
			margin: 0 0 20px;
		}
		&:hover {
			opacity: .7;
		}
		> a {
			text-decoration: none;
			color: #222;
			display: block;
			padding: 36px 0 20px 18px;
		}
		&-date {
			font-family: "Heebo-Bold";
			font-size: 46px;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			line-height: 1;
			z-index: 2;
			&-top {
				font-size: 14px;
				margin: 0 0 5px;
			}
		}
		&-figure {
			position: relative;
			margin: 0;
			padding: 0;
			z-index: 1;
			img {
				width: 100%;
			}
		}
		&-text {
			position: relative;
			margin: -50px 0 0;
			background: rgba(255,255,255,0.90);
			box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.15);
			z-index: 2;
			margin: -60px 31px 0;
			padding: 27px 30px 35px;
		}
		&-title {
			font-size: 14px;
			line-height: 1.7;
		}
	}
	.cards {
		padding: 0 30px 0 10px;
		&-btns {
			display: none;
		}
		&-list {
			overflow: hidden;
		}
	}
	
}
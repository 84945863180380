@import "../../assets/scss/variables.scss";

.tags {
	display: flex;
	justify-content: center;
	list-style-type: none;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	&-item {
		margin: 0 10px 20px;
		border: 1px solid #C5C5C5;
		border-radius: 20px;
		a {
			padding: 10px 31px 8px;
			line-height: 1;
			font-size: 14px;
			font-weight: 600;
		}
		&:hover {
			opacity: .7;
		}
		&-active {
			background: #222222;
			a {
				color: #fff;
			}
		}
	}
}


@include mq(md) {
	.tags {
		justify-content: flex-start;
		padding: 0 20px;
		&-item {
			margin: 0 20px 20px 0;
		}
	}
}

.tags-list {
	display: flex;
	list-style-type: none;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style-type: none;
	&-item {
		line-height: 1;
		margin: 0 10px 10px 0;
		button ,
		span {
			padding: 0;
			line-height: 1;
			font-size: 12px;
			text-align: left;
			min-width: initial;
			min-width: auto;
			color: #DE0000 !important;
			&:hover {
				text-decoration: underline;
				background: inherit;
			}
		}
	}
}
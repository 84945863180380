@import "../../assets/scss/variables.scss";

.cart {
  &-item {
    &-no {
      text-align: center;
      padding: 37px 0;
      font-size: 14px;
      margin: 0;
      font-weight: 300;
      border-top: 1px solid #E6E6E6;
      border-bottom: 1px solid #E6E6E6;
    }
  }
  &-table {
    min-width: 650px;
  }
  &-btns {
    text-align: center;
    padding: 60px 0 0; 
  }
}
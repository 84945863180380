@import "../../../../assets/scss/variables.scss";

.hero {
  width: 100%;
  height: 780px;
  background-position: center center;
  background-size: cover;
  color: #fff;
  padding: 297px 0 0 22%;
  margin: 0 0 60px;
  box-sizing: border-box;
  &-container {
    margin: 0 0 110px;
  }
  &-info {
    display: inline-block;
  }
  &-text {
    font-size: 16px;
    font-weight: bold;
    transform: rotate(-3deg);
    letter-spacing: 0;
    line-height: 42px;
    margin: -20px 0 0 16px;
  }
}

@include mq(md) {
  .hero {
    width: 100%;
    height: 600px;
    padding: 67px 20px 0;
    margin: 0 0 40px;
    text-align: center;
    &-container {
      margin: 0 0 80px;
    }
    &-info {
      text-align: center;
      margin: 0 auto;
    }
    &-text {
      text-align: left;
      transform: rotate(0);
      line-height: 36px;
      margin: 30px 0 0 0;
    }
  }
}
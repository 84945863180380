@import "../../assets/scss/variables.scss";

.footer {
  position: relative;
  &-to-top {
    font-size: 13px;
    margin: 0 100px 32px;
    padding: 0 40% 0 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    color: #555;
    a {
      font-size: 13px;
      color: #555;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      margin: 0 8px 0 0;
    }
    li {
      padding: 0 0 10px;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(n+2) {
        &::before {
          margin-right: 8px;
          content: "／";
        }
      }
    }
  }
  &-inner {
    padding: 116px 100px 92px;
    background: #D8D8D8;
  }
  &-related {
    position: absolute;
    top: 0;
    right: 0;
    width: 46%;
    padding: 60px 46px;
    box-sizing: border-box;
    background: #222;
    color: #fff;
    line-height: 42px;
    &-title {
      color: #fff;
      font-size: 14px;
      margin: 0;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    &-item {
      &-icon {
        margin: 0 0 0 8px;
        img {
          width: 10px;
          height: 10px;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-list {
    margin: 0 60px 0 0;
    padding: 0;
    list-style-type: none;
    &:last-child {
      margin: 0;
    }
    &-inner {
      display: flex;
      padding: 0 45.2% 50px 0;
      border-bottom: 1px solid #BCBCBC;
      ul {
        padding: 0;
        list-style-type: none;
        a {
          text-decoration: none;
          color: #222;
          &:hover {
            text-decoration: underline;
          }
        }
        li {
          margin: 0 0 22px;
          > a {
            font-weight: 600;
            font-size: 14px;
          }
          ul {
            margin: 22px 0 0;
            li {
              margin: 0 0 10px;
              &:last-child {
                margin: 0;
              }
              > a {
                font-weight: 300;
                font-size: 12px;
              }
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &-about {
      margin: 0 0 33px !important;
    }
    &-member {
      margin: 0 0 106px !important;
    }
  }
  &-copy {
    &-container {
      display: flex;
      justify-content: space-between;
      padding: 45px 0 0;
    }
    &-inner {
      order: 1;
      display: flex;
      align-items: center;
      small {
        font-size: 11px;
      }
    }
    &-logo {
      margin-right: 60px;
      img {
        vertical-align:bottom;
      }
    }
    &-athor {
      order: 2;
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin-left: 35px;
        &:first-child {
          margin: 0;
        }
        a {
          text-decoration: none;
          color: #222;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}


@include mq(md) {
  .footer {
    &-to-top {
      display: none;
    }
    &-inner {
      padding: 0 0 58px;
    }
    &-related {
      position: static;
      width: 100%;
      padding: 40px 30px;
      line-height: 20px;
      &-title {
        margin: 0 0 20px;
      }
      &-item {
        margin: 0 0 20px;
        &:last-child {
          margin: 0;
        }
      }
    }
    &-list {
      margin: 0;
      &-inner {
        display: block;
        margin: 0 30px;
        padding: 40px 0 10px;
        ul {
          li {
            margin: 0 0 30px;
            ul {
              margin: 10px 0 0;
              padding: 0 0 0 20px;
              li {
                margin: 0 0 10px;
              }
            }
            &:last-child {
              margin: 0 0 30px;
            }
          }
        }
      }
      &-about {
        margin: 0 0 30px !important;
      }
      &-member {
        margin: 0 0 30px !important;
      }
    }
    &-copy {
      &-container {
        display: block;
        padding: 45px 30px 0;
      }
      &-inner {
        order: 2;
        display: block;
        text-align: center;
        small {
          display: block;
        }
      }
      &-logo {
        display: block;
        margin-right: 0;
        margin-bottom: 34px;
      }
      &-athor {
        order: 1;
        justify-content: center;
        margin: 0 0 26px;
      }
    }
  }
}
.payment {
  &-stripe {
    border: 1px solid #CCCCCC;
    padding: 17px 20px 18px;
    &-title {
      font-size: 14px;
      color: #333333 !important;
      font-weight: bold;
      margin: 0 0 12px;
    }
    &-wrapper {
      margin-bottom: 30px;
    }
  }
  &-load {
    &-elements {
      margin-top: 28px !important;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #CCCCCC;
    }
  }
  &-btns {
    text-align: right;
    &-group {
      text-align: right;
      &__center {
        text-align: center;
      }
      > * {
        margin: 0 5px;
      }
    }
  }
}
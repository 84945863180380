@import "../../assets/scss/variables.scss";

.order {
  &-btn {
    text-align: center;
  }
  &-comp-text {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 20px;
  }
}
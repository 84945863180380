/* width */

$sm-width: 599px;
$md-width: 959px;
$lg-width: 1079px;
$xl-width: 1919px;

/* transion time */

$short-time: .4s;
$medium-time: .6s;
$long-time: .8s;

/* breakpoints */

$breakpoints: (
  'sm': 'screen and (max-width: '$sm-width')',
  'md': 'screen and (max-width: '$md-width')',
  'lg': 'screen and (max-width: '$lg-width')',
  'xl': 'screen and (max-width: '$xl-width')',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.page-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  width: 100%;
  max-width: 1120px;
  padding-left: 20px;
  padding-right: 20px;
  &--full {
    width: 100%;
    max-width: initial;
    max-width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btn-group {
  &--center {
    text-align: center;
  }
}
@import "../../../../assets/scss/variables.scss";

.ihic {
  &-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    &::before {
      content: "";
      display: block;
      height: 70%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #D1D1D1;
      z-index: -1;
    }
  }
  &-img {
    width: 50%;
    min-width: 640px;
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  &-info {
    width: 49%;
    background: rgba(222, 0, 0, .8);
    color: #FFFFFF;
    padding: 55px 50px;
    position: relative;
    top: 92px;
    left: -6.25%;
    &-title {
      font-size: 26px;
      font-weight: 600;
      color: #FFFFFF;
      letter-spacing: 1.62px;
      line-height: 26px;
      margin: 0 0 30px;
    }
    &-text {
      font-size: 16px;
      color: #FFFFFF;
      line-height: 36px;
      margin: 0 0 76px;
    }
  }
}

@include mq(lg) {
  .ihic {
    &-container {
      display: block;
      &::before {
        content: none;
      }
    }
    &-img {
      width: 100%;
      min-width: initial;
      min-width: auto;
    }
    &-info {
      width: 100%;
      background: rgba(222, 0, 0, .9);
      padding: 35px 0 80px;
      top: 0;
      left: 0;
      &-title {
        font-size: 20px;
        padding: 0 14px;
      }
      &-text {
        padding: 0 14px;
      }
      &-btn {
        text-align: center;
      }
    }
  }
}
@import "./variables.scss";

@import url(https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css);

@font-face {
	font-family: 'Heebo-Black';
	src: url(../font/Heebo/Heebo-Black.ttf);
}

@font-face {
	font-family: 'Heebo-Bold';
	src: url(../font/Heebo/Heebo-Bold.ttf);
}

@font-face {
	font-family: 'Heebo-ExtraBold';
	src: url(../font/Heebo/Heebo-ExtraBold.ttf);
}

@font-face {
	font-family: 'Heebo-ExtraLight';
	src: url(../font/Heebo/Heebo-ExtraLight.ttf);
}

@font-face {
	font-family: 'Heebo-Light';
	src: url(../font/Heebo/Heebo-Light.ttf);
}

@font-face {
	font-family: 'Heebo-Medium';
	src: url(../font/Heebo/Heebo-Medium.ttf);
}

@font-face {
	font-family: 'Heebo-Regular';
	src: url(../font/Heebo/Heebo-Regular.ttf);
}

@font-face {
	font-family: 'Heebo-SemiBold';
	src: url(../font/Heebo/Heebo-SemiBold.ttf);
}

@font-face {
	font-family: 'Heebo-Thin';
	src: url(../font/Heebo/Heebo-Thin.ttf);
}


@font-face {
	font-family: 'Yesie';
	src: url(../font/Yesie/Yesie.otf);
}

body {
	font-family: YakuHanJP,
		"Helvetica Neue",
		Arial,
		"Hiragino Kaku Gothic ProN",
		Meiryo,
		sans-serif;
}

.sp-hide {
	display: block;
}

.pc-hide {
	display: none;
}

@include mq(md) {
	.sp-hide {
		display: none;
	}
	.pc-hide {
		display: block;
	}
}

@import "../../assets/scss/variables.scss";

.sns {
  margin: 0 auto 135px;
  padding: 0 20px;
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
  &-item {
    width: 30%;
    line-height: 1;
    &-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 30px;
    }
    &-inner {
      border: 1px solid #C5C5C5;
    }
  }
  &-container {
    height: 400px;
    position: relative;
    overflow-y: scroll;
  }
  &-load {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include mq(md) {
	.sns {
    margin: 0 auto 60px;
    width: 100%;
    padding: 0 30px;
    display: block;
    &-item {
      width: 100%;
      margin: 0 0 40px;
    }
  }
}
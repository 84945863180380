@import "../../assets/scss/variables.scss";

.pagination {
  display: flex;
  justify-content: center;
  margin: 80px 0 100px;
  &-btn {
    background: none;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    svg {
      font-size: 14px;
      margin: 0 4px;
    }
    &:first-child {
      margin: 0 40px 0 0;
    }
    &:last-child {
      margin: 0 0 0 40px;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  &-list {
    li {
      button {
        background: #333;
        color: #fff;
        &:global(.Mui-selected) {
          background: none;
          color: #222;
        }
      }
    }
  }
}


@include mq(md) {
  .pagination {
    &-btn {
      display: none;
    }
  }
}
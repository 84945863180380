@import "../../assets/scss/variables.scss";

.privacy {
  &-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 120px;
  }
  &-title {
    font-size: 20px;
    color: #000;
    letter-spacing: 1.25px;
    line-height: 26px;
    font-weight: 600;
    margin: 0 0 23px;
  }
  &-text {
    font-size: 16px;
    color: #000;
    line-height: 36px;
    margin: 0 0 32px;
    &-right {
      text-align: right;
      font-size: 13px;
      color: #000;
      line-height: 26px;
    }
  }
}

@include mq(md) {
  .privacy {
    &-container {
      max-width: initial;
      max-width: auto;
      padding: 0 20px;
      margin: 0 auto 80px;
    }
  }
}
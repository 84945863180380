@import "../../assets/scss/variables.scss";

.password {
  &-btns {
    text-align: right;
    &-group {
      text-align: right;
      > * {
        margin: 0 5px;
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.container {
	padding-top: 70px;
}

@include mq(md) {
	.container {
		padding-top: 60px;
	}	
}
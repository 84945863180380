@import "../../assets/scss/variables.scss";

.ihic-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 30px;
  &-text {
    width: 52%;
    &__text {
      font-size: 16px;
      color: #222;
      letter-spacing: 0;
      line-height: 42px;
      margin: 0 0 32px;
      &-mb {
        margin: 0 0 75px;
      }
    }
  }
  &-img {
    width: 41%;
    position: relative;
    margin: 0;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
    &__text {
      font-feature-settings: "palt";
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      height: 30px;
      background-color: rgba(0,0,0,.6);
      padding: 0 20px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

@include mq(md) {
  .ihic-about {
    display: block;
    padding-top: 0;
    &-text {
      width: 100%;
      margin: 0 0 80px;
      &__text {
        &-mb {
          margin: 0 0 55px;
        }
      }
    }
    &-img {
      width: 100%;
    }
  }
}

.ihic-list {
  display: flex;
  justify-content: space-between;
  width: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  &-item {
    width: 30%;
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    &-text {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      display: block;
      margin: 30px 0 0;
    }
  }
}

@include mq(md) {
  .ihic-list {
    display: block;
    &-item {
      width: 100%;
      margin: 0 0 60px;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.ihic-booking {
  &-title {
    margin: 0 0 60px;
  }
  &-container {
    padding: 75px 0 100px;
    margin: 0 0 116px;
    background: #F5F5F5;
    p {
      font-size: 16px;
      color: #000;
      line-height: 36px;
      margin: 0 0 32px;
    }
    &-inner {
      margin-bottom: 0;
    }
  }
  &-profile {
    display: flex;
    justify-content: flex-start;
    &-img {
      width: 178px;
      height: 100%;
    }
    &-text {
      width: calc(100% - 178px);
      padding-left: 42px;
      dl {
        margin: 0 0 16px;
        dt {
          font-weight: 600;
          font-size: 16px;
          color: #222222;
          line-height: 38px;
        }
        dd {
          font-weight: 600;
          font-size: 24px;
          color: #222222;
          line-height: 38px;
          margin: 0;
        }
      }
      p {
        font-size: 16px;
        color: #222222;
        line-height: 38px;
        margin: 0;
      }
    }
  }
}

@include mq(md) {
  .ihic-booking {
    &-title {
    &-container {
      padding: 75px 0 80px;
      }
    }
    &-profile {
      display: block;
      text-align: center;
      &-text {
        width: 100%;
        padding-left: 0;
        p {
          text-align: left;
        }
      }
    }
  }
}

.ihic-content {
  &-container {
    max-width: 750px;
  }
  &-title {
    font-weight: 600;
    font-size: 26px;
    color: #000000;
    letter-spacing: 1.62px;
    line-height: 26px;
    margin-bottom: 57px;
  }
  &-list {
    counter-reset: ol;
    list-style: none;
    padding: 0;
    margin: 0 22% 0 0;
    &:last-child {
      margin-right: 0;
    }
    &-container {
      display: flex;
    }
    &-item {
      font-weight: 600;
      font-size: 16px;
      color: #222222;
      line-height: 28px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        font-family: "Hiragino Kaku Gothic ProN";
        counter-increment: ol;
        content: counter(ol);
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}

@include mq(md) {
  .ihic-content {
    &-container {
      max-width: 750px;
    }
    &-title {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      color: #222222;
      letter-spacing: 0;
      text-align: center;
      line-height: 26px;
      margin-bottom: 50px;
    }
    &-list {
      counter-reset: ol;
      list-style: none;
      padding: 0;
      margin: 0 22% 0 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &-container {
        display: block;
      }
      &-item {
        font-weight: 600;
        font-size: 16px;
        color: #222222;
        line-height: 28px;
        margin-bottom: 20px;
        padding: 0 0 0 20px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          font-family: "Hiragino Kaku Gothic ProN";
          counter-increment: ol;
          content: counter(ol);
          display: inline-block;
          margin-right: 8px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
@import "../../assets/scss/variables.scss";

.member {
  &-text {
    &-group {
      font-size: 16px;
      color: #222222;
      text-align: center;
      line-height: 42px;
    }
  }
  &-title {
    font-size: 26px;
    color: #000000;
    letter-spacing: 1.62px;
    line-height: 26px;
    text-align: center;
    margin: 0 0 42px;
    font-weight: 600;
    &-sub {
      background: #DE0000;
      font-size: 18px;
      color: #fff;
      text-align: center;
      line-height: 18px;
      padding: 13px;
      font-weight: 600;
      margin: 0 0 32px;
    }
  }
  &-table {
    width: 100%;
    border-top: 1px solid #c5c5c5;
    margin: 0 0 7px;
    tr {
      td {
        text-align: center;
        padding: 17px 40px;
        border-bottom: 1px solid #c5c5c5;
        font-size: 18px;
        color: #000000;
        letter-spacing: 1.12px;
        line-height: 26px;
        width: 33%;
        font-weight: bold;
        &.member-table-right {
          text-align: right;
        }
      }
    }
    &-container {
      background: #F2F2F2;
      padding: 60px 160px;
    }
    &-caption {
      text-align: right;
      font-size: 14px;
      color: #222222;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &-benefits {
    text-align: center;
    &-text {
      font-size: 16px;
      color: #000000;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      margin: 0 0 40px;
    }
  }
  &-payment {
    padding: 0 90px;
    &-item {
      width: 44%;
    }
    &-img {
      padding: 0;
      margin: 0 0 14px;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
    &-text {
      font-size: 16px;
      color: #000000;
      letter-spacing: 1px;
      line-height: 36px;
      margin: 0 0 47px;
    }
    &-btn {
      margin: 0 0 20px;
    }
    &-sub {
      font-size: 18px;
      color: #222222;
      text-align: center;
      line-height: 18px;
      padding: 18px 0 17px;
      font-weight: 600;
      margin: 0 0 14px;
    }
  }
}


@include mq(md) {
  .member {
    &-text {
      &-group {
        text-align: left;
      }
    }
    &-table {
      border-top: none;
      margin: 0 0 18px;
      tr {
        display: flex;
        flex-wrap: wrap;
        td {
          width: 100%;
          display: inline-block;
          text-align: left;
          padding: 20px 10px;
          font-size: 14px;
          color: #000000;
          font-weight: bold;
          &.member-table-right {
            width: 50%;
            text-align: left;
          }
        }
      }
      &-container {
        padding: 40px 20px;
      }
      &-caption {
        text-align: left;
      }
    }
    &-benefits {
      &-text {
        text-align: left;
      }
    }
    &-payment {
      padding: 0;
      &-item {
        width: 100%;
        margin: 0 0 60px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.member-list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #E6E6E6;
  &-title {
    font-size: 26px;
    color: #000000;
    letter-spacing: 1.62px;
    line-height: 26px;
    font-weight: 600;
    margin: 0 0 30px;
  }
  &-container {
    display: flex;
    justify-content: space-between;
  }
  &-wrapper {
    width: 48%;
  }
  &-item {
    border-bottom: 1px solid #e6e6e6;
    font-size: 18px;
    color: #000000;
    line-height: 60px;
  }
  &-date {
    font-size: 13px;
    color: #000000;
    text-align: right;
    line-height: 26px;
  }
}

@include mq(md) {
  .member-list {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #E6E6E6;
    &-title {
      font-size: 26px;
      color: #000000;
      letter-spacing: 1.62px;
      line-height: 26px;
      font-weight: 600;
      margin: 0 0 30px;
    }
    &-container {
      display: block;
    }
    &-wrapper {
      width: 100%;
      + .member-list-wrapper {
        .member-list {
          border: none;
        }
      }
    }
    &-item {
      font-size: 14px;
      line-height: 16px;
      padding: 20px 0 20px 10px;
    }
  }
}
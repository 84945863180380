.logout {
  &-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto 120px;
    text-align: center;
  }
  &-text {
    font-size: 18px;
    margin: 0 0 30px;
  }
}
@import "../../assets/scss/variables.scss";

.support {
  &-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto 120px;
  }
  &-privacy {
    &-container {
      background: #EEEEEE;
      text-align: center;
      padding: 9px;
      margin: 0 0 60px;
      color: #333333;
      a {
        color: #333333;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          opacity: .7;
        }
      }
    }
  }
  &-btn {
    text-align: center;
  }
  &-comp-text {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 20px;
  }
}

@include mq(md) {
  .support {
    &-container {
      width: 100%;
      max-width: initial;
      max-width: auto;
      margin: 0 auto 120px;
      padding: 0 20px;
    }
    &-privacy {
      &-container {
        background: #EEEEEE;
        text-align: center;
        padding: 9px;
        margin: 0 0 60px;
      }
    }
    &-btn {
      text-align: center;
    }
  }
}